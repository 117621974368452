import { Injectable } from '@angular/core';

/**
 * This will be served as a wrapper for all the icon-related implementations.
 */

enum IconType {
	/**
	 * All icons should be defined here.
	 *
	 * Download all the icons here https://fontawesome.com/icons/sliders?f=classic&s=solid as SVG and always download the default one.
	 * TODO: Change all fontawesome icons to downloaded icons in the assets folder
	 */
	BOOKMARK = 'fg-general-4 fa-solid fa-bookmark',
	HEART = 'fg-general-4 fa-solid fa-heart',
	BELL = 'fg-general-4 fa-solid fa-bell',
	LIST = 'fg-general-4 fa-solid fa-list',
	CALENDAR = 'fg-general-2 fa-solid fa-calendar',
	PALETTE = 'fg-general-2 fa-solid fa-palette',
	GUAGE = 'fg-general-2 fa-solid fa-gauge',
	CIRCLEPLUS = 'fa-circle-plus',
	BED = 'fg-general-2 fa-solid fa-bed',
	BATH = 'fg-general-2 fa-solid fa-bath ',
	SQUARE = 'fg-general-2 fa-solid  fa-vector-square',
	FACEBOOK = 'fa-brands fa-square-facebook ',
	INSTAGRAM = 'fa-brands fa-instagram',
	TIKTOK = 'fa-brands fa-tiktok',
	SNAPCHAT = 'fa-brands fa-snapchat',
	PEN = 'fa-solid fa-pen',
	TRASH = 'fg-general-4 fa-solid fa-trash',
	TRASH_ERROR_3 = 'fg-error-3 fa-solid fa-trash',
	SHARE = 'fg-prim-2 fa-solid fa-share-from-square',
	ROCKET = 'fg-basic-1 fa-solid fa-rocket',
	USER = 'fg-general-4 fa-solid fa-circle-user',
	CHEVRONLEFT = 'fa-solid fa-chevron-left',
	CHEVRONRIGHT = 'fa-solid fa-chevron-right',
	LOCATION = 'fg-general-2 fa-solid fa-location-dot',
	SCREW_DRIVER = 'fg-general-2 fa-solid fa-screwdriver',
	CHEVRON_RIGHT = 'fg-general-5 fa-solid fa-chevron-right',
	CHEVRON_LEFT = 'fg-general-5 fa-solid fa-chevron-left',
	FAVORITE = 'fg-general-4 fa-regular fa-heart',
	CHEVRON_RIGHT_GENERAL_3 = 'fg-general-3 fa-solid fa-chevron-right',
	CHEVRON_LEFT_GENERAL_3 = 'fg-general-3 fa-solid fa-chevron-left',
	EXPIRE = 'fg-general-4 fa-solid fa-clock',
	CAR = 'fg-general-4 fa-solid fa-car-side',
	EDIT = 'fg-prim-2 fa-solid fa-pen',
	QUESTION = 'error-2 fa-solid fa-circle-question',
	CLOSE = 'fg-info-3 fa-solid fa-circle-xmark ',

	// Icons from the assets folder
	BOOKMARK_SOLID = 'bookmark-solid',
	SLIDERS_SOLID = 'sliders-solid',
	ARROW_LEFT = 'fg-prim-2 fa-solid fa-arrow-left',
	HEART_ERROR_3 = 'fg-error-3 fa-solid fa-heart',
	CLOSE_DIALOG = 'fg-neutral-2 fa-solid fa-xmark',
	EXCLAMATION = 'fg-error-3 fa-solid fa-circle-exclamation',
	RIGHTBRACKET = 'fa-solid fa-arrow-right-from-bracket',
	IMAGE = 'fg-basic-1 fa-regular fa-image',
}

enum IconSize {
	SIZE_12 = 'icon icon--size-12',
	SIZE_14 = 'icon icon--size-14',
	SIZE_18 = 'icon icon--size-18',
	SIZE_20 = 'icon icon--size-20',
	SIZE_22 = 'icon icon--size-22',
	SIZE_24 = 'icon icon--size-24',
	SIZE_30 = 'icon icon--size-30',
}

@Injectable({
	providedIn: 'root',
})
export class IconService {
	readonly type = IconType;
	readonly size = IconSize;
}
